var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExpand),expression:"!showExpand"}],staticClass:"pr-0 pb-0",attrs:{"cols":"12","md":_vm.showHelp ? 8 : 11,"sm":12}},[_c('PageHeader',{staticClass:"pt-5",attrs:{"title":_vm.title}}),_c('v-card',{staticClass:"mx-auto mb-4 pt-0",attrs:{"max-width":"1200","outlined":""}},[_c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"10","align":"left"}},[(!_vm.showFilters)?_c('FiltersSelected',{attrs:{"filters":_vm.filtersApplyed}}):_vm._e()],1),_c('v-col',{staticClass:"py-0 mt-2 mb-2",attrs:{"cols":"12","md":"2","align":"right","align-self":"center"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text",on:{"click":function($event){$event.stopPropagation();return _vm.toggleFiltersSelected($event)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.closeFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(!_vm.showFilters ? "Mostrar filtros" : "Ocultar filtros"))])])],1)],1),_c('v-expand-transition',{attrs:{"mode":"out-in"}},[_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],ref:"filters-form",attrs:{"id":"filters-form"},on:{"submit":function($event){$event.preventDefault();return _vm.applyFilters()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',{staticClass:"pr-4"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{ref:"empresa-autocomplete",attrs:{"items":_vm.empresaItems,"return-object":"","item-text":"value","item-value":"id","outlined":"","clearable":"","rules":_vm.rules.required,"dense":"","autocomplete":"off","label":"Entidad facturante","hint":"Nombre","persistent-hint":""},model:{value:(_vm.empresaSelected),callback:function ($$v) {_vm.empresaSelected=$$v},expression:"empresaSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{ref:"agentes-autocomplete",attrs:{"items":_vm.agentesFacturacionItems,"search-input":_vm.agentesFacturaAutocompleteInput,"item-text":"value1","item-value":"id","filter":_vm.customFilterAgentes,"rules":_vm.rules.required,"outlined":"","clearable":"","return-object":"","dense":"","autocomplete":"off","label":"Agente de facturación","hint":"Nombre, CUIT o código","persistent-hint":""},on:{"update:searchInput":function($event){_vm.agentesFacturaAutocompleteInput=$event},"update:search-input":function($event){_vm.agentesFacturaAutocompleteInput=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.value1))]),_c('v-list-item-subtitle',[_vm._v("Código: "+_vm._s(item.value2)+" ")])],1)]}},{key:"append",fn:function(){return [(_vm.agentesLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"28","color":"primary"}}):_vm._e()]},proxy:true}]),model:{value:(_vm.agenteFacturacionSelected),callback:function ($$v) {_vm.agenteFacturacionSelected=$$v},expression:"agenteFacturacionSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{ref:"convenios-autocomplete",staticClass:"p-0",attrs:{"items":_vm.itemsConvenio,"label":"Convenio","return-object":"","item-text":"value","item-value":"id","outlined":"","clearable":"","dense":""},on:{"change":function($event){return _vm.loadSubconvenios()}},model:{value:(_vm.convenioSelected),callback:function ($$v) {_vm.convenioSelected=$$v},expression:"convenioSelected"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"3"}},[_c('v-menu',{ref:"fecha-desde",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha desde","append-icon":_vm.calendarIcon,"autocomplete":"not","hint":"Formato DD/MM/AAAA","outlined":"","dense":"","clearable":"","rules":_vm.rules.required.concat(
                            _vm.rules.validDate,
                            new Date(_vm.parseDateToIso(_vm.fechaDesdeSelected)) <=
                              new Date(_vm.parseDateToIso(_vm.fechaHastaSelected)) ||
                              'Formato incorrecto'
                          )},on:{"blur":function($event){_vm.fechaDesde = _vm.parseDateToIso(_vm.fechaDesdeSelected)}},model:{value:(_vm.fechaDesdeSelected),callback:function ($$v) {_vm.fechaDesdeSelected=$$v},expression:"fechaDesdeSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaDesde),callback:function ($$v) {_vm.menuFechaDesde=$$v},expression:"menuFechaDesde"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaDesdeSelected = _vm.formatDate(_vm.fechaDesde)},"input":function($event){_vm.menuFechaDesde = false}},model:{value:(_vm.fechaDesde),callback:function ($$v) {_vm.fechaDesde=$$v},expression:"fechaDesde"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"3"}},[_c('v-menu',{ref:"fecha-hasta",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha hasta","append-icon":_vm.calendarIcon,"hint":"Formato DD/MM/AAAA","outlined":"","autocomplete":"not","dense":"","clearable":"","rules":_vm.rules.required.concat(
                            _vm.rules.validDate,
                            new Date(_vm.parseDateToIso(_vm.fechaDesdeSelected)) <=
                              new Date(_vm.parseDateToIso(_vm.fechaHastaSelected)) ||
                              'Formato incorrecto'
                          )},on:{"blur":function($event){_vm.fechaHasta = _vm.parseDateToIso(_vm.fechaHastaSelected)}},model:{value:(_vm.fechaHastaSelected),callback:function ($$v) {_vm.fechaHastaSelected=$$v},expression:"fechaHastaSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFechaHasta),callback:function ($$v) {_vm.menuFechaHasta=$$v},expression:"menuFechaHasta"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaHastaSelected = _vm.formatDate(_vm.fechaHasta)},"input":function($event){_vm.menuFechaHasta = false}},model:{value:(_vm.fechaHasta),callback:function ($$v) {_vm.fechaHasta=$$v},expression:"fechaHasta"}})],1)],1),(this.paramGralMultimoneda)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"3","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.monedaItems,"rules":_vm.rules.required,"label":"Moneda de consulta","return-object":"","item-text":"value","item-value":"id","outlined":"","clearable":"","dense":""},model:{value:(_vm.monedaSelected),callback:function ($$v) {_vm.monedaSelected=$$v},expression:"monedaSelected"}})],1):_vm._e(),(this.paramGralMultimoneda)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","md":"3"}},[_c('v-menu',{ref:"fecha-cotiza",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha cotización","append-icon":_vm.calendarIcon,"hint":"Formato DD/MM/AAAA","outlined":"","autocomplete":"not","dense":"","clearable":"","rules":_vm.rules.required.concat(_vm.rules.validDate)},on:{"blur":function($event){_vm.fechaCotiza = _vm.parseDateToIso(_vm.fechaCotizaSelected)}},model:{value:(_vm.fechaCotizaSelected),callback:function ($$v) {_vm.fechaCotizaSelected=$$v},expression:"fechaCotizaSelected"}},'v-text-field',attrs,false),on))]}}],null,false,460850851),model:{value:(_vm.menuFechaCotiza),callback:function ($$v) {_vm.menuFechaCotiza=$$v},expression:"menuFechaCotiza"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaCotizaSelected = _vm.formatDate(_vm.fechaCotiza)},"input":function($event){_vm.menuFechaCotiza = false}},model:{value:(_vm.fechaCotiza),callback:function ($$v) {_vm.fechaCotiza=$$v},expression:"fechaCotiza"}})],1)],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"3"}},[_c('v-switch',{staticClass:"mt-n1",attrs:{"label":"Solo factura con vencimiento al:"},model:{value:(_vm.facturaConVenc),callback:function ($$v) {_vm.facturaConVenc=$$v},expression:"facturaConVenc"}})],1),(this.facturaConVenc)?_c('v-col',{staticClass:"py-0 ml-n10",attrs:{"cols":"6","md":"3"}},[_c('v-menu',{ref:"fecha-vencimiento",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha de vencimiento","append-icon":_vm.calendarIcon,"hint":"Formato DD/MM/AAAA","outlined":"","autocomplete":"not","dense":"","clearable":"","rules":_vm.rules.required.concat(_vm.rules.validDate)},on:{"blur":function($event){_vm.fechaVencimiento = _vm.parseDateToIso(
                            _vm.fechaVencimientoSelected
                          )}},model:{value:(_vm.fechaVencimientoSelected),callback:function ($$v) {_vm.fechaVencimientoSelected=$$v},expression:"fechaVencimientoSelected"}},'v-text-field',attrs,false),on))]}}],null,false,881519221),model:{value:(_vm.menuFechaVencimiento),callback:function ($$v) {_vm.menuFechaVencimiento=$$v},expression:"menuFechaVencimiento"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaVencimientoSelected = _vm.formatDate(
                          _vm.fechaVencimiento
                        )},"input":function($event){_vm.menuFechaVencimiento = false}},model:{value:(_vm.fechaVencimiento),callback:function ($$v) {_vm.fechaVencimiento=$$v},expression:"fechaVencimiento"}})],1)],1):_vm._e(),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"3"}},[_c('v-switch',{staticClass:"mt-n1",attrs:{"label":"Incluir comprobantes relacionados"},model:{value:(_vm.incluirCompRelacion),callback:function ($$v) {_vm.incluirCompRelacion=$$v},expression:"incluirCompRelacion"}})],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"3"}},[_c('v-switch',{staticClass:"mt-n1",attrs:{"label":"Solo comprobante con saldo"},model:{value:(_vm.soloComprobanteConSaldo),callback:function ($$v) {_vm.soloComprobanteConSaldo=$$v},expression:"soloComprobanteConSaldo"}})],1),(this.paramGralSubConvenio)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{ref:"subConvenios-autocomplete",staticClass:"p-0",attrs:{"items":_vm.itemsSubConvenio,"disabled":this.haveConvenioSelected,"label":"Subconvenio","return-object":"","item-text":"value","item-value":"id","outlined":"","clearable":"","dense":""},model:{value:(_vm.subConvenioSelected),callback:function ($$v) {_vm.subConvenioSelected=$$v},expression:"subConvenioSelected"}})],1):_vm._e()],1),_c('v-row',{staticClass:"pr-4 pl-4",attrs:{"justify":"end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":"28"},on:{"click":_vm.resetForm}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.clearFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v("Limpiar filtros")])]),_c('v-btn',{attrs:{"color":"primary","elevation":"2","small":"","type":"submit","disabled":!_vm.isFormValid,"form":"filters-form"}},[_vm._v(" Aplicar ")])],1)],1)],1),(this.haveCotiza && this.paramGralMultimoneda)?_c('p',{class:_vm.showFilters
                ? 'invalidConfigText mb-n1'
                : 'invalidConfigText mb-n1 ml-2'},[_vm._v(" Existen comprobantes sin cotización ")]):_vm._e()],1)],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersCompGral,"items":_vm.itemsComprobantes,"height":"100%","fixed-header":"","loading":_vm.isLoadingTable,"item-key":"pfCompId"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',{staticClass:"primary--text"},[_vm._v("Comprobantes")])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.goToVerComprobante(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.seeIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Ver comprobante")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.toggleComprobantesCancelatorios(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.comprobanteCancelatorioIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Ver comprobantes cancelatorios")])])]}}],null,true)})],1),_c('v-card',{staticClass:"mt-4"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersCompSinRelacionar,"items":_vm.itemsComprobantesSinRelacionar,"height":"100%","fixed-header":"","loading":_vm.isLoadingTable,"item-key":"pfCompId"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',{staticClass:"primary--text"},[_vm._v("Comprobantes sin relacionar")])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.goToVerComprobanteSinRela(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.seeIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Ver comprobante")])])]}}],null,true)})],1),_c('v-row',{staticClass:"mt-3"},[_c('v-col',{staticClass:"d-flex py-0",attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{staticClass:"right-input",attrs:{"label":_vm.saldoInicialView,"outlined":"","persistent-hint":"","disabled":"","filled":"","readonly":"","dense":""},model:{value:(_vm.saldoInicial),callback:function ($$v) {_vm.saldoInicial=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"saldoInicial"}})],1),_c('v-col',{staticClass:"d-flex py-0",attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{staticClass:"right-input",attrs:{"label":_vm.saldoFinalView,"outlined":"","persistent-hint":"","disabled":"","filled":"","readonly":"","dense":""},model:{value:(_vm.saldoFinal),callback:function ($$v) {_vm.saldoFinal=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"saldoFinal"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"40%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"fontsize to-right",attrs:{"color":"primary","outlined":"","disabled":_vm.itemsComprobantes.length == 0},on:{"click":_vm.exportExcelModelo}},'v-btn',attrs,false),[_vm._v(" Exportar comprobantes ")])]}}])})],1)],1)],1),(_vm.showIcon)?_c('v-col',{staticClass:"text-right pt-3 px-0",attrs:{"cols":"12","md":!_vm.showHelp ? 1 : _vm.showExpand ? 12 : 4}},[_c('Ayuda',{attrs:{"optionCode":_vm.optionCode,"pantallaTitle":_vm.title},on:{"toggleExpand":function($event){_vm.showExpand = !_vm.showExpand},"toggleHelp":_vm.toggleHelp,"toggleIcon":function($event){return _vm.toggleIcon()}}})],1):_vm._e()],1),(_vm.modalComprobanteCancelatorio)?_c('v-dialog',{attrs:{"max-width":"60%"},model:{value:(_vm.modalComprobanteCancelatorio),callback:function ($$v) {_vm.modalComprobanteCancelatorio=$$v},expression:"modalComprobanteCancelatorio"}},[_c('ComprobantesCancelatoriosFacturaOS',{attrs:{"pfCompId":_vm.pfCompId,"monedaSelected":this.filtrosGuardados.monedaId,"fechaCotiza":this.filtrosGuardados.fechaCotiza,"comprobantesCancelatorios":_vm.itemsComprobantesCancel},on:{"closeModalComprobantesCancelatorios":_vm.closeModalComprobantesCancelatorios}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }